body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
      'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h6-branded {
   font-family: 'ITC Avant Garde Gothic LT Demi', sans-serif;
   font-size: 14px;
   font-weight: 700;
   line-height: 17.81px;
   letter-spacing: 0.02em;
   text-align: left;
   text-underline-position: from-font;
   text-decoration-skip-ink: none;
}

.pp-RMHQDM6CVU4SY {
   text-align: center;
   border: none;
   border-radius: 0.25rem;
   min-width: 11.625rem;
   padding: 0 2rem;
   height: 2.625rem;
   font-weight: bold;
   background-color: #ffd140;
   color: #000000;
   font-family: 'Helvetica Neue', Arial, sans-serif;
   font-size: 1rem;
   line-height: 1.25rem;
   cursor: pointer;
}

.h6-branded {
   font-family: 'ITC Avant Garde Gothic LT Demi', sans-serif;
   font-size: 14px;
   font-weight: 700;
   line-height: 17.81px;
   letter-spacing: 0.02em;
   text-align: left;
   text-underline-position: from-font;
   text-decoration-skip-ink: none;
}

.pp-RMHQDM6CVU4SY {
   text-align: center;
   border: none;
   border-radius: 0.25rem;
   min-width: 11.625rem;
   padding: 0 2rem;
   height: 2.625rem;
   font-weight: bold;
   background-color: #ffd140;
   color: #000000;
   font-family: 'Helvetica Neue', Arial, sans-serif;
   font-size: 1rem;
   line-height: 1.25rem;
   cursor: pointer;
}

.h6-branded {
   font-family: 'ITC Avant Garde Gothic LT Demi', sans-serif;
   font-size: 14px;
   font-weight: 700;
   line-height: 17.81px;
   letter-spacing: 0.02em;
   text-align: left;
   text-underline-position: from-font;
   text-decoration-skip-ink: none;
}

.w-3rem {
   width: 2rem;
   height: 1.75rem;
}